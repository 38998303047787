import * as React from 'react'

import {ChakraProvider, Flex, HStack, Image, Link} from '@chakra-ui/react'

function App() {
  return (
    <ChakraProvider>
      <Flex alignItems="center" w="100vw" h="100vh" flexDirection="column" bg="#0e3663" px={10}>
        <Image src='/img/happify-big-logo.svg' alt='Happify' w={400} mb={75} mt={150}/>
        <HStack spacing={5}>
          <Link href="https://apps.apple.com/us/app/happify-control/id1595662945">
            <Image src='/img/appstore-badge.svg' alt='Happify' w={150}/>
          </Link>
          <Link href="https://play.google.com/store/apps/details?id=gt.com.happify.control">
            <Image src='/img/google-play-badge.png' alt='Happify' w={150}/>
          </Link>
        </HStack>
      </Flex>
    </ChakraProvider>
  )
}

export default App;
